export const PROJECTS = [
  {
    id: '1',
    title: 'Search Engine',
    description:
      'Developed Search Engine to find student by typing name with four different filters, followed scalabale and optimized strategy so that this module can able to search student in 5-6 sec from 2 lac students.',
    image: {
      src: 'https://hteksolution-portfolio.s3.ap-south-1.amazonaws.com/sample1.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://apps.apple.com/us/app/syntho/id1615419392',
    },
    technologies: [
      'NextJS 14',
      'React',
      'javascript',
      'NodeJS',
      'PosgreSQL',

      ,
    ],
  },
  {
    id: '2',
    title: 'OASIS Inspection System',
    description:
      'The simplest and fastest vision inspection system in the precision parts manufacturing industry, developed desktop application using Electron and React in which expert can inspect the parts by importing image and placing appropiate tools on part image.',
    image: {
      src: 'https://hteksolution-portfolio.s3.ap-south-1.amazonaws.com/sample2.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://apps.apple.com/us/app/minfin-currency-rates/id1574863878',
    },
    technologies: [
      'Electron',
      'React',
      'Typescript',
      'i18next',
      
    ],
  },
  {
    id: '3',
    title: 'Chat Application',
    description:
      'cutting-edge chat application. Developed using MERN stack. Designed with scalability and performance in mind. implemented 1-1 & participating in group discussions, our intuitive interface ensures smooth navigation and effortless communication.',
    image: {
      src: 'https://hteksolution-portfolio.s3.ap-south-1.amazonaws.com/sample3.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://expo.dev/@v_snaichuk/rn-fashion?serviceType=classic&distribution=expo-go',
      repo: 'https://github.com/vsnaichuk/fashion-app',
    },
    technologies: [
      'React JS',
      'Node JS',
      'Express JS',
      'MongoDB',
      'WebSocket',
    ],
  },
  {
    id: '4',
    title: 'Video Call Application',
    description:
      'An online marketplace made with React. The project was developed on 8th Apiko Intensive.',
    image: {
      src: 'https://hteksolution-portfolio.s3.ap-south-1.amazonaws.com/sample4.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://apiko-marketplace-app-snaichuk.herokuapp.com/',
      repo: 'https://github.com/vsnaichuk/apiko-marketplace-app-snaichuk',
    },
    technologies: [
      '#react',
      '#redux',
      '#socket.io',
      '#scss',
      '#formik',
      '#react-window',
      '#restapi',
    ],
  },
  {
    id: '5',
    title: 'OpenSymmetry',
    description:
      'A MERN app to let users share their experience of places along with Details. It is user friendly and can be useful to many others.',
    image: {
      src: 'https://hteksolution-portfolio.s3.ap-south-1.amazonaws.com/sample5.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://yourplaces-sv.netlify.app/',
      repo: 'https://github.com/vsnaichuk/MERN-places',
    },
    technologies: [
      '#react',
      '#nodejs',
      '#express',
      '#mongodb',
      '#react-query',
      '#react-transition-group',
      '#mongoose',
      '#jwt',
      '#aws',
    ],
  },
  {
    id: '6',
    title: 'eCommerce Website',
    description:
      'A Mobile eCommerce application built with React Native that includes cart functionality, push notifications, user authentication and user orders',
    image: {
      src: 'https://hteksolution-portfolio.s3.ap-south-1.amazonaws.com/sample6.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://expo.dev/@v_snaichuk/online-shop-app',
      repo: 'https://github.com/vsnaichuk/RN-shop',
    },
    technologies: [
      '#react-native',
      'expo',
      '#redux',
      '#redux-toolkit',
      '#react-navigation',
      '#nodejs',
      '#express',
      '#mongodb',
    ],
  },
  {
    id: '7',
    title: 'Blog site',
    description:
      'A Mobile application for sharing places that people visit from all over the world. Uses React Native, TypeScript, MobX and Native Device Features',
    image: {
      src: 'https://hteksolution-portfolio.s3.ap-south-1.amazonaws.com/sample7.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://expo.dev/@v_snaichuk/rn-places',
      repo: 'https://github.com/vsnaichuk/RN-places',
    },
    technologies: [
      '#react-native',
      '#typescript',
      '#expo',
      '#mobx',
      '#sqlite',
      '#react-native-maps',
      '#expo-location',
    ],
  },
  {
    id: '8',
    title: 'Organization Management',
    description:
      'Simple eCommerce cart application built with React and TypeScript. Uses SWR hook to fetch the data from the API and Styled Components',
    image: {
      src: 'https://hteksolution-portfolio.s3.ap-south-1.amazonaws.com/sample8.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://shopping-cart-sv.netlify.app/',
      repo: 'https://github.com/vsnaichuk/React-ShoppingCart',
    },
    technologies: [
      '#react',
      '#typescript',
      '#material-ui',
      '#styled-components',
      '#swr',
      '#fakestoreapi',
    ],
  },
  {
    id: '9',
    title: 'Mushaf (Elearning Quran)',
    description:
      'Simple eCommerce cart application built with React and TypeScript. Uses SWR hook to fetch the data from the API and Styled Components',
    image: {
      src: 'https://hteksolution-portfolio.s3.ap-south-1.amazonaws.com/sample9.png',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://shopping-cart-sv.netlify.app/',
      repo: 'https://github.com/vsnaichuk/React-ShoppingCart',
    },
    technologies: [
      '#react',
      '#typescript',
      '#material-ui',
      '#styled-components',
      '#swr',
      '#fakestoreapi',
    ],
  },
];
